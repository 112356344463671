import { Component, OnInit, Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-help-text',
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.scss'],
})
export class HelpTextComponent implements OnInit {

  @Input('text') text : string;


  get sanitizedText() {
    return this.sanitizer
    .bypassSecurityTrustHtml(this.text || "")
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
