import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private defaultState = new BehaviorSubject(false);
  currentState = this.defaultState.asObservable();

constructor() { }

changeState(state: boolean) {
  this.defaultState.next(state)
}

}
