import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import * as uuid from 'uuid';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomSelectOption } from '../custom-select/models/custom-select-option';
import { CustomAutocompleteSearch } from './models/custom-autocomplete-search';
import { CustomAutocompleteService } from './services/custom-autocomplete.service';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CustomAutocompleteComponent implements OnInit {
  constructor(private _httpClient: HttpClient) {}
  @Input('placeholder') placeholder: string = '';
  @Input('formControl') formControl: FormControl;
  @Input('customClass') customClass: string;
  @Input('label') label: string;
  @Input('id') id: string = uuid.v4();
  @Input('search') search: CustomAutocompleteSearch | undefined;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Output() onValueChanges = new EventEmitter<any>();
  @Output() onValueSelected = new EventEmitter<CustomSelectOption>();

  protected filteredOptions: CustomSelectOption[];
  protected hasErrors: boolean = false;

  protected defaultDurationInMillisecond = 200;
  protected defaultcountToSearch = 1;
  protected isSearching: boolean = false;
  protected flag: boolean = false;

  ngOnInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => {
          return (
            res.length >=
            (this.search?.countToSearch || this.defaultcountToSearch)
          );
        }),
        debounceTime(
          this.search?.durationInMiliseconds ||
            this.defaultDurationInMillisecond
        ),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this._onValueChanges(text);
        this._initSearch(text);
      });
  }
  private _onValueChanges(value: any) {
    this.hasErrors = false;
    this.onValueChanges.emit(value);
    if (!!this.formControl.errors) {
      this.hasErrors = true;
      this.formControl.setErrors({ incorrect: true });
    }
  }
  private _initSearch(value: any) {
    let search = this.search;
    this.isSearching = true;
    if (search !== undefined && !!search.prefix && !!search.method) {
      let service = new CustomAutocompleteService(
        this._httpClient,
        search.prefix
      );
      service.getDictionary(search.method, value).subscribe((data) => {
        this.filteredOptions = data;
        this.isSearching = false;
        this.checkErrors();
      });
    }
  }
  displayFn(option: CustomSelectOption): string {
    if (option && option.text) {
      this.hasErrors = false;
      return option.text;
    }
    return '';
  }
  checkErrors() {
    let value: CustomSelectOption = this.formControl.value;
    if (!!value) {
      if (!this.isCustomSelectOption(value)) {
        let stringValue = String(value).toLowerCase().trim();
        let filteredOptions = this.filteredOptions;
        let find = filteredOptions?.find(
          (x) => x.text.toLocaleLowerCase().trim() === stringValue
        );
        if (!!find) {
          this.formControl.setValue(find);
          this.formControl.setErrors(null);
        } else {
          this.hasErrors = true;
          this.formControl.setErrors({ incorrect: true });
        }
      } else {
        this.hasErrors = false;
        this.formControl.setErrors(null);
      }
    }
  }
  isCustomSelectOption(item: any): item is CustomSelectOption {
    try {
      return 'value' in item && 'text' in item;
    } catch (e) {
      return false;
    }
  }

  onOptionSelected(e: MatAutocompleteSelectedEvent) {
    this.onValueSelected.emit(e.option.value);
  }
}
