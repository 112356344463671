import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileDocument } from './models/file-document.model';

@Component({
  selector: 'app-file-upload-with-sending',
  templateUrl: './file-upload-with-sending.component.html',
  styleUrl: './file-upload-with-sending.component.scss',
})
export class FileUploadWithSendingComponent {
  @Output() uploadingFilesEvent = new EventEmitter<FileDocument[]>();
  @Output() downloadFileEvent = new EventEmitter<number>();
  @Output() deleteFileEvent = new EventEmitter<number>();
  @Input() files: FileDocument[] = [];
  @Input() loading: boolean = false;

  uploadedFiles(uploadedFiles: FileDocument[]) {
    this.uploadingFilesEvent.emit(uploadedFiles);
  }

  downloadFile(fileId: number) {
    this.downloadFileEvent.emit(fileId);
  }

  deleteFile(file: FileDocument) {
    this.deleteFileEvent.emit(file.id);
  }
}
