import { CallbackColumnSetting } from './callback-column.component';
import { ColumnType } from './column.type';
import { DateParams } from './date-column.component';
import { IconColumnSetting } from './icon-column.component';
import { LinkParams } from './link-column.component';
import { MenuConfig } from './menu-column.component';

export interface Column {
  type: ColumnType;
  header: string;
  fieldName: string;
  hidden: boolean;
  sortable: boolean;
  params?: any;
  allwaysHidden: boolean;
  allwaysShow: boolean;
}

export class ColumnDef implements Column {
  type: ColumnType;
  header: string;
  fieldName: string;
  hidden: boolean = false;
  sortable: boolean = true;
  params?: any;
  allwaysHidden: boolean = false;
  allwaysShow: boolean = false;

  constructor(
    type: ColumnType,
    header: string,
    fieldName: string,
    hidden: boolean = false,
    params?: any
  ) {
    this.type = type;
    this.header = header;
    this.fieldName = fieldName;
    this.hidden = hidden;
    this.params = params;
  }
}

export class TextColumnDef extends ColumnDef {
  constructor(header: string, filedName: string, hidden: boolean = false,sortable : boolean = true) {
    super(ColumnType.Text, header, filedName, hidden);
    this.sortable = sortable;
  }
}

export class MenuColumnDef extends ColumnDef {
  constructor(
    header: string,
    filedName: string,
    params: MenuConfig[],
    hidden: boolean = false
  ) {
    super(ColumnType.Menu, header, filedName, hidden, params);
    this.sortable = false;
    this.allwaysShow = true;
  }
}

export class HiddenColumnDef extends ColumnDef {
  constructor(header: string, filedName: string, hidden: boolean = false) {
    super(ColumnType.Hidden, header, filedName, hidden);
    this.allwaysHidden = true;
  }
}

export class DateColumnDef extends ColumnDef {
  constructor(
    header: string,
    fieldName: string,
    hidden: boolean = false,
    params: DateParams = { dateFormat: '',onlyDate : false },
    sortable : boolean = true,
  ) {
    super(ColumnType.Date, header, fieldName, hidden, params);
    this.sortable = sortable;
  }
}

export class LinkColumnDef extends ColumnDef {
  constructor(
    header: string,
    fieldName: string,
    params: LinkParams,
    hidden: boolean = false
  ) {
    super(ColumnType.Link, header, fieldName, hidden, params);
  }
}

export class CallbackColumnDef extends ColumnDef {
  constructor(
    header: string,
    filedName: string,
    params: CallbackColumnSetting,
    hidden: boolean = false
  ) {
    super(ColumnType.Callback, header, filedName, hidden, params);
  }
}

export class IconColumDef extends ColumnDef {
  constructor(
    header: string,
    fileName: string,
    params: IconColumnSetting,
    hidden: boolean = false
  ) {
    super(ColumnType.Icon, header, fileName, hidden, params);
    this.sortable = false;
    this.allwaysShow = true;
  }
}

export class CheckBoxColumnDef extends ColumnDef {
  constructor() {
    super(ColumnType.CheckBox, 'CheckBox', 'CheckBox', false);
    this.sortable = false;
    this.allwaysShow = true;
  }
}
