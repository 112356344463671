<button [type]="type" [attr.form]="form" class="btn" [ngClass]="bClass" (click)="onClick()" [disabled]="disabled">
  <p class="btn__text" [ngStyle]="{'font-weight': !!filterCount ? 'bold' : ''}" [ngClass]="tClass">{{ text }}</p>
  <span *ngIf="!!filterCount"class="btn__filter">{{filterCount}}</span>
  <mat-progress-spinner
    *ngIf="isLoading"
    [color]="spinnerColor"
    [diameter]="20"
    mode="indeterminate"
  ></mat-progress-spinner>
</button>
