import { Component, Input } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

@Component({
  template: `{{ this.params.data[this.params.fieldName] }}`,
})
export class TextColumnComponent implements ColumnComponent {
  @Input() params: ColumnElementParams;
}
