import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { BarSelectorService } from '@app/shared/services/bar-selector.service';
import { Dictionary } from 'app/core/models/dictionary.model';
import { Subject, Subscription, combineLatest, map, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@app/modules/accounts/services/users.service';
import { TokenService } from '@app/modules/home/services/token.service';
import { AuthService } from 'services/auth-service/auth.service';
import { SpinnerService } from '../spinner/spinner/spinner-service.service';
import { RoleService, Roles } from '@app/core/services/role.service';
import { BarSelectorBS } from '@app/shared/model/bar-selector-bs.model';
import { DivisionsApiService } from '@app/shared/services/divisions-api.service';
import { ProgramsApiService } from '@app/shared/services/programs-api.service';
import { DivisionsService } from '@app/shared/services/divisions.service';
import { FeatureFlagService } from '@app/shared/FeatureFlags/services/feature-flag.service';
import { ProgramService } from '@app/shared/services/program.service';
import { AccountsService } from '@app/shared/services/accounts.service';
import { FeatureFlagEnum } from '@app/shared/FeatureFlags/feature-flag.enum';
import { UserAccount } from '@app/modules/accounts/models/user-account';

@Component({
  selector: 'app-bar-selector',
  templateUrl: './bar-selector.component.html',
  styleUrls: ['./bar-selector.component.scss'],
  providers: [
    AccountsService,
    DivisionsService,
    DivisionsApiService,
    ProgramService,
    ProgramsApiService,
    UsersService,
    TokenService,
    AuthService,
    FeatureFlagService,
  ],
})
export class BarSelectorComponent implements OnInit, OnDestroy {
  public accounts: Dictionary[] = [];
  public divisions: Dictionary[] = [];
  public programs: Dictionary[] = [];
  public subProgram: Dictionary[] = [];

  public selectedAccount: Dictionary;
  public selectedDivision: Dictionary = BarSelectorService.divisionDefault;
  public selectedProgram: Dictionary = BarSelectorService.programDefault;
  public selectedSubProgram: Dictionary = BarSelectorService.subProgramDefault;

  public loaded = false;

  @Input() onlyAccounts: boolean = false;
  @Input() hideSubprogram: boolean = false;

  private subscription: Subscription | null = null;
  private makorAPI_SubPrograms_6082: boolean = false;
  private makorAPI_Programs_options_6056: boolean = false;
  private makorAPI_Divisions_6055: boolean = false;
  private ngFeatureFlagSubscription = new Subject<void>();
  constructor(
    private accountsService: AccountsService,
    private divisionsService: DivisionsService,
    private divisionsApiService: DivisionsApiService,
    private elementRef: ElementRef,
    private barSelectorService: BarSelectorService,
    private identitySerivce: UsersService,
    private tokenService: TokenService,
    private programService: ProgramService,
    private programsApiService: ProgramsApiService,
    private spinnerSrv: SpinnerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private router: Router,
    private featureFlagService: FeatureFlagService
  ) {
    barSelectorService.subscribeAccounts((x) => {
      let value = barSelectorService.getAccounts();
      if (value.active == null) return;

      let selected = value.dictionary.find((x) => x.id == value.active.id);
      this.selectedAccount = selected as Dictionary;
      this.accounts = value.dictionary;
      this.loaded = true;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this.ngFeatureFlagSubscription.next();
    this.ngFeatureFlagSubscription.complete();

  }

  ngOnInit(): void {
    this.featureFlagService.getAllFlags().pipe(takeUntil(this.ngFeatureFlagSubscription)).subscribe((x) => {
      this.makorAPI_SubPrograms_6082 = x.find(
        (x) => x.flag == FeatureFlagEnum.MakorAPI_SubPrograms_6082
      )?.state as boolean;
      this.makorAPI_Programs_options_6056 = x.find(
        (x) => x.flag == FeatureFlagEnum.MakorAPI_Programs_options_6056
      )?.state as boolean;
      this.makorAPI_Divisions_6055 = x.find(
        (x) => x.flag == FeatureFlagEnum.MakorAPI_Divisions_6055
      )?.state as boolean;
      this.getDivision().then(() => {
        this.init();
      });
    });
  }

  init() {
    if (this.subscription == null) {
      this.subscription = this.barSelectorService.subscribe((data) => {
        this.subscription?.unsubscribe();
        this.subscription = null;
        let promises = [];
        if (data.division.id != 0) {
          promises.push(this.getProgramDict(data.division.id));
        }
        if (data.program.id != 0) {
          promises.push(this.getSubProgramDict(data.program.id));
        }
        if (promises.length > 0) {
          Promise.all(promises).then(() => {
            this.setAndCheck(data);
          });
        } else {
          this.setAndCheck(data);
        }
      });
    }
  }

  getDivision() {
    let method = this.accountsService.getDivisionsDictionary();
    if (this.makorAPI_Divisions_6055) {
      method = this.divisionsApiService.getByLoggedUser();
    }
    return new Promise((resolve) => {
      method.subscribe((result) => {
        this.divisions = [BarSelectorService.divisionDefault, ...result];
        resolve(true);
      });
    });
  }

  selectDivision(id: number) {
    this.selectedDivision = this.divisions.filter((x) => x.id === id)[0];
    this.resetProgram();
    if (id != 0) this.getProgramDict(id);

    this.resetSubProgram();
    this.updateValues();
  }

  getProgramDict(id: number) {
    if (this.makorAPI_Programs_options_6056) {
      return new Promise((resolve) => {
        this.programsApiService
          .getProgramsDictionary(id)
          .subscribe((result) => {
            this.programs = [BarSelectorService.programDefault, ...result];
            resolve(true);
          });
      });
    } else {
      return new Promise((resolve) => {
        this.divisionsService.getProgramsDictionary(id).subscribe((result) => {
          this.programs = [
            BarSelectorService.programDefault,
            ...result.map((e) => {
              return {
                id: e.id ? +e.id : 0,
                name: e.text,
              };
            }),
          ];
          resolve(true);
        });
      });
    }
  }

  selectProgram(id: number) {
    this.selectedProgram = this.programs.filter((x) => x.id === id)[0];
    this.resetSubProgram();

    if (id != 0) this.getSubProgramDict(id);

    this.updateValues();
  }

  getSubProgramDict(id: number) {
    if (this.makorAPI_SubPrograms_6082) {
      return new Promise((resolve) => {
        this.programsApiService.getSubprogramOptions(id).subscribe((result) => {
          this.subProgram = [
            BarSelectorService.subProgramDefault,
            ...result.map(
              (x) =>
                ({
                  id: x.id,
                  name: x.text,
                } as Dictionary)
            ),
          ];
          resolve(true);
        });
      });
    } else {
      return new Promise((resolve) => {
        this.programService.getSubProgramSelect(id).subscribe((result) => {
          this.subProgram = [BarSelectorService.subProgramDefault, ...result];
          resolve(true);
        });
      });
    }
  }

  selectSubProgram(id: number) {
    this.selectedSubProgram = this.subProgram.filter((x) => x.id === id)[0];

    this.updateValues();
  }

  selectAccount(id: number) {
    this.selectedAccount = this.accounts.filter((x) => x.id === id)[0];
    this.spinnerSrv.changeState(true);
    this.identitySerivce
      .switchAccount(this.selectedAccount.id)
      .subscribe((x) => {
        this.resetDivision();
        this.resetProgram();
        this.resetSubProgram();
        this.tokenService.resetToken().subscribe((response) => {
          this.authService.saveDataInBrowser(response);
          this.roleService.setRoles().add(() => {
            this.getDivision();
            this.identitySerivce.getMyAccountInfo().subscribe((data) => {
              this.checkAccess().then(e=>{
                this.barSelectorService.setAccounts(
                  data.userAccounts as UserAccount[],
                  data.activeUserAccount
                );
                this.updateValues().then(() => {
                  this.spinnerSrv.changeState(false);
                });
              })
           
            });
          });
        });
      });
  }
  private checkAccess(){
    return new Promise((resolve,reject)=>{
      this.roleService.getUserRolesStore().subscribe(roles=>{
        let rolesWithRedirects = this.roleService.rolesWithRedirects;
        let url = this.router.url;
        let redirects = rolesWithRedirects.filter(roleWithRedirect => roleWithRedirect.roles.some(r => roles.includes(r)));
        let r = redirects.find(e=>e.redirectTo === url);
        if(!r){
          var roleWithRedirect = redirects[0];
          if(roleWithRedirect?.redirectTo !== undefined){
             this.router.navigate([roleWithRedirect?.redirectTo]);
          }
        }
        resolve(true);
      }).unsubscribe();
    })
   
  }

  private setAndCheck(data: BarSelectorBS) {
    if (this.divisions.map((x) => x.id).includes(data.division.id)) {
      this.selectedDivision = data.division;
      if (this.programs.map((x) => x.id).includes(data.program.id)) {
        this.selectedProgram = data.program;
        this.selectedSubProgram = data.subProgram;
      } else {
        this.resetSubProgram();
        this.selectedProgram = BarSelectorService.programDefault;
      }
    } else {
      this.selectedDivision = BarSelectorService.divisionDefault;
      this.resetProgram();
      this.resetSubProgram();
    }
    this.fixData();
  }


  private resetDivision() {
    this.selectedDivision = BarSelectorService.divisionDefault;
    this.divisions = [];
  }

  private resetProgram() {
    this.selectedProgram = BarSelectorService.programDefault;
    this.programs = [];
  }

  private resetSubProgram() {
    this.selectedSubProgram = BarSelectorService.subProgramDefault;
    this.subProgram = [];
  }

  private updateValues(emitEvent = true) {
    return new Promise((resolve) => {
      this.barSelectorService
        .nextValue(
          this.selectedDivision,
          this.selectedProgram,
          this.selectedSubProgram
        )
        .then(() => {
          resolve(true);
        });
    });
  }

  private fixData() {
    if (this.selectedDivision == undefined || this.selectedDivision == null) {
      this.selectedDivision = BarSelectorService.divisionDefault;
    }
    if (this.selectedProgram == undefined || this.selectedProgram == null) {
      this.selectedProgram = BarSelectorService.programDefault;
    }
    if (
      this.selectedSubProgram == undefined ||
      this.selectedSubProgram == null
    ) {
      this.selectedSubProgram = BarSelectorService.subProgramDefault;
    }
  }
}
