import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.scss'],
  providers: [CookieService],
})
export class CookieNoticeComponent implements OnInit {

  private readonly cookieName : string = "cookie_notice";

  constructor(private cookieService: CookieService) { }

  ngOnInit() {
  }

  protected get isCookieConfirmed(){
    return this.cookieService.get(this.cookieName) === "1";
  }

  confirmCookies(){
    this.cookieService.set(this.cookieName,"1");
  }
}
