import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner-service.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor(private srv:SpinnerService) {
  }

  protected turnedOn : boolean = false;

  ngOnInit(): void {
    this.srv.currentState.subscribe(state=>{this.turnedOn=state; });
  }


}
