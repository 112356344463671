<div class="dialog-container">
  <p>{{inputSettings.title}}</p>
  <form class="dialog-form"  class="" [formGroup]="formGroup">
    <app-custom-input [label]="inputSettings.message" [formControl]="inputSettings.formControl" ngDefaultControl></app-custom-input>
  </form>
  <div class="button-section">
    <app-button
      color="accent-empty"
      text="Cancel"
      [mat-dialog-close]
    ></app-button>
    <app-button text="Apply"  (btnClick)="onApplyButtop()"></app-button>
  </div>
</div>
