import { Component, Input, ElementRef } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

export interface CallbackColumnSetting {
  onClick?:(data:any)=>void
}

@Component({
  template: `<div (click)="onClick()"><span style="font-weight: bold; cursor: pointer;">{{ this.params.data[this.params.fieldName] }}</span></div>`,
})
export class CallbackColumnComponent implements ColumnComponent {
  @Input() params: ColumnElementParams;

  constructor(private elementRef: ElementRef){}

  onClick():void{
    if(this.params.params.onClick)
      this.params.params?.onClick(this.params);
  }
}
