
<div class="custom-form-group">
  <label [for]="id" *ngIf="!!label" class="custom-select-label">{{label}}<span class="custom-select-red" *ngIf="required">*</span></label>
  <div [ngClass]="{'custom-select-input-no-label': !label}" class="custom-select-input-wrapper">
    <select
    [class.--on-submit-error]="formControl.invalid"
    [class.--error]="hasErrors"
    [class.custom-select-disabled]="formControl.disabled"
    [ngClass]="customClass"
    [formControl]="formControl"
    type="text"
    [id]="id"
    [attr.disabled]="disabled ? true : null"
    class="custom-select-input"
    #selectInput>
      <option *ngIf="placeholder.length>0" value="" disabled selected hidden>{{placeholder}}</option>
      <option *ngFor="let option of options" [value]="option.value">{{option.text}}</option>
    </select>
  </div>
</div>
