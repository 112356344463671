<div class="content-header-container">
  <span>{{ header }}
  </span>
  <span *ngIf="withSearch" style="width: 300px;margin-left:10px">
    <form (ngSubmit)="searchSubmit()">
      <app-custom-input ngDefaultControl [formControl]="searchFormControl"></app-custom-input>
    </form>
  </span>
  <div class="content">
    <ng-content> </ng-content>
  </div>
</div>
<div #underline class="underline"></div>
