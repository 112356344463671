import { Component, EventEmitter, Input, OnInit,Output,SimpleChanges} from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import * as uuid from 'uuid';
@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class CustomTextareaComponent implements OnInit {

  constructor() { }

  @Input('formControl') formControl : FormControl;
  @Input('customClass') customClass : string;
  @Input('label') label : string;
  @Input('rows') rows : number = 5;
  @Input('id') id : string = uuid.v4();

  protected showPasswordInput : boolean = false;
  protected required: boolean = false;
  protected hasErrors : boolean = false;

  ngOnInit() {
    if (this.formControl.hasValidator(Validators.required)) {
     this.required = true;
    }
    this.formControl.valueChanges.subscribe((value)=>{
      this.hasErrors = false;
      if(!!this.formControl.errors){
         this.hasErrors = true;
      }
    });
  }

}
