<div class="dialog-container">
  <div class="help-contact-form-container">
    <div class="help-contact-form-container__top">
      <p>
        We are here to help! Please complete this form to submit your request.
        We will be in touch shortly with a response. We appreciate your
        business.
      </p>
    </div>
    <div class="help-contact-form-container__mid">
      <form
        [formGroup]="contactFormGroup"
        class="help-contact-form-container__mid-form"
        id="contact-form"
        (submit)="onSubmit()"
      >
        <app-custom-input
          label="Name"
          [formControl]="contactFormGroup.controls.name"
          ngDefaultControl
        ></app-custom-input>
        <app-custom-input
          label="Phone"
          [formControl]="contactFormGroup.controls.phone"
          ngDefaultControl
        ></app-custom-input>
        <app-custom-input
          label="Email"
          [formControl]="contactFormGroup.controls.email"
          ngDefaultControl
        ></app-custom-input>
        <app-custom-input
          label="Company"
          [formControl]="contactFormGroup.controls.company"
          ngDefaultControl
        ></app-custom-input>
        <app-custom-input
          label="Location"
          [formControl]="contactFormGroup.controls.location"
          ngDefaultControl
        ></app-custom-input>
        <app-custom-textarea
          label="Message"
          [formControl]="contactFormGroup.controls.message"
          ngDefaultControl
        ></app-custom-textarea>
      </form>
    </div>
    <div class="help-contact-form-container__bottom">
      <app-button
        [mat-dialog-close]
        text="Cancel"
        color="accent-empty"
      ></app-button>
      <app-button
        type="submit"
        form="contact-form"
        type="submit"
        text="SUBMIT"
        color="primary"
      ></app-button>
    </div>
  </div>
</div>
