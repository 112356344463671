<div class="custom-form-group">
    <label [for]="id" *ngIf="!!label" class="custom-label-input">{{label}}<span class="custom-input-red" *ngIf="required">*</span></label>
    <div class="custom-textarea-input-wrapper">
        <textarea
        class="custom-textarea-input"
        [class.--on-submit-error]="formControl.invalid"
        [class.--error]="hasErrors"
        [class.custom-input-disabled]="formControl.disabled"
        [ngClass]="customClass"
        [formControl]="formControl"
        [id]="id"
        [rows]="rows"
        >
        </textarea>
    </div>
</div>
