<div class="dialog-container">
  <p>{{filterSettings.title}}</p>
  <form class="dialog-form" [formGroup]="filtrForm">
    <div class="column-selector">
      <div class="column-input">
        <div class="cell"  *ngFor="let index of firstColumnElements">
          <app-custom-input *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.Input" [label]="filterSettings.inputs[index].label"
          [formControl]="getFormCustom(index)" ngDefaultControl></app-custom-input>
          <app-custom-autocomplete *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.InputAutocomplete" [label]="filterSettings.inputs[index].label"
          [formControl]="getFormCustom(index)" [search]="filterSettings.inputs[index].onAutoComplete" ngDefaultControl></app-custom-autocomplete>
          <app-custom-select *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.Select" [label]="filterSettings.inputs[index].label"
          [formControl]="getFormCustom(index)"
          [options]="filterSettings.inputs[index].selectOptions ?? []" [withNullOption]="false" ngDefaultControl></app-custom-select>
          <app-custom-datepicker-range *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.DateRange" [label]="filterSettings.inputs[index].label"
           [formGroup]=" getFormGroup(index)"></app-custom-datepicker-range>
        </div>
      </div>
      <div class="column-input">
        <div class="cell"  *ngFor="let index of secondColumnElements">
          <app-custom-input *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.Input" [label]="filterSettings.inputs[index].label"
           [formControl]="getFormCustom(index)" ngDefaultControl></app-custom-input>
          <app-custom-autocomplete *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.InputAutocomplete" [label]="filterSettings.inputs[index].label"
          [formControl]="getFormCustom(index)" [search]="filterSettings.inputs[index].onAutoComplete" ngDefaultControl></app-custom-autocomplete>
          <app-custom-select *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.Select" [label]="filterSettings.inputs[index].label"
          [formControl]="getFormCustom(index)"
          [options]="filterSettings.inputs[index].selectOptions ?? []" [withNullOption]="false" ngDefaultControl></app-custom-select>
          <app-custom-datepicker-range *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.DateRange" [label]="filterSettings.inputs[index].label"
          [formGroup]=" getFormGroup(index)"></app-custom-datepicker-range>
          <app-custom-check-box *ngIf="filterSettings.inputs[index].inputType===InputTypeEnum.Checkbox" ngDefaultControl [label]="filterSettings.inputs[index].label"
          [formControl]=" getFormCustom(index)"></app-custom-check-box>
        </div>
      </div>
    </div>
  </form>
  <div class="button-section">
    <app-button
      color="accent-empty"
      text="Clear All"
      (btnClick)="onFilterClearAll()"
    ></app-button>
    <app-button
      color="accent-empty"
      text="Cancel"
      (btnClick)="onClose()"
      [mat-dialog-close]="null"
    ></app-button>
    <app-button text="APPLY"  (btnClick)="onApplyClick()"></app-button>
  </div>
</div>
