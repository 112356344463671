import { Component, EventEmitter, Input, OnInit,Output,Renderer2,SimpleChanges} from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import * as uuid from 'uuid';
@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class CustomInputComponent implements OnInit {

  constructor() { }

  @Input('placeholder') placeholder : string = "";
  @Input('formControl') formControl : FormControl;
  @Input('customClass') customClass : string;
  @Input('label') label : string;
  @Input('id') id : string = uuid.v4();
  @Input('disabled') disabled : boolean = false;
  @Input('type') type : string | "password" | "text";
  @Input('matAutocomplete') matAutocomplete : MatAutocomplete | null;

  @Output('onValueChanges') onValueChanges = new EventEmitter<any>();

  protected showPasswordInput : boolean = false;
  protected required: boolean = false;
  protected hasErrors : boolean = false;

  handlePasswordInput(){
    this.showPasswordInput = !this.showPasswordInput;
  }
  
  ngOnInit() {
    if (this.formControl.hasValidator(Validators.required)) {
     this.required = true;
    }
    this.formControl.valueChanges.subscribe((value)=>{
      this.hasErrors = false;
      if(!!this.formControl.errors){
         this.hasErrors = true;
      }
      this.onValueChanges.emit(value);
    });
  }


}
