<div class="custom-form-group" [ngStyle]="{ 'min-width': minWidth + 'px' }">
  <label *ngIf="!!label" class="custom-label-input">{{ label }}</label>
  <div class="custom-date-range-picker-wrapper">
    <div class="custom-date-range-inputs">
      <mat-form-field
        class="custom-date-field custom-start-date"
        [class.--error]="hasError"
      >
        <input
          class="custom-date-input"
          matInput
          [matDatepicker]="dp"
          [formControl]="formControlStart"
          (dateChange)="selectStart($event)"
        />
        <mat-datepicker #dp></mat-datepicker>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
      </mat-form-field>
      <div class="custom-date-separator">-</div>
      <mat-form-field
        class="custom-date-field custom-end-date"
        [class.--error]="hasError"
      >
        <input
          class="custom-date-input"
          matInput
          [matDatepicker]="dp2"
          [formControl]="formControlEnd"
          (dateChange)="selectEnd($event)"
        />
        <mat-datepicker #dp2></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-form-field [formGroup]="formGroup" class="hidden-date-range">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="start" />
        <input matEndDate formControlName="end" />
      </mat-date-range-input>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-icon
      (click)="clearValues()"
      *ngIf="!!formGroup.value.start || !!formGroup.value.end"
      class="custom-date-range-picker-close-icon"
      >close</mat-icon
    >
  </div>
</div>
