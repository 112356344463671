import { Component, Input } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

export interface LinkParams {
  route: string;
  routeParams: (number | string)[];
}

@Component({
  template: `<a routerLink="{{ this.finalRoute }}">{{
    this.params.data[this.params.fieldName]
  }}</a>`,
  styles: ['a { text-decoration: none;color:#1761bd }'],
})
export class LinkColumnComponent implements ColumnComponent {
  @Input() params: ColumnElementParams;
  linkParams: LinkParams;
  finalRoute: string = '';

  ngOnInit() {
    let linkParams: LinkParams = <LinkParams>this.params.params;
    this.finalRoute = this.prepareRoute(linkParams);
  }

  prepareRoute(linkParams: LinkParams) {
    let result = linkParams.route;

    linkParams.routeParams.forEach((x, i) => {
      let str = `{${i}}`;

      result = result.replace(str, this.params.data[x.toString()]);
    });

    return result;
  }
}
