import { Component, Input, OnInit } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

interface ProgressParams {
  max: number;
}

@Component({
  template: `<div class="progress-container">
    <mat-progress-bar
      mode="determinate"
      [value]="progressValue"
    ></mat-progress-bar>
  </div>`,
  styles: ['.progress-container { margin: 0 10px 0 0 }'],
})
export class ProgressColumnComponent implements ColumnComponent, OnInit {
  @Input() params: ColumnElementParams;
  progressValue: number;

  ngOnInit() {
    let progressParams: ProgressParams = <ProgressParams>this.params.params;
    this.progressValue = this.getPercentageValue(progressParams.max);
  }

  getPercentageValue(maxValue: number): number {
    return (+this.params.data[this.params.fieldName] * 100) / maxValue;
  }
}
