import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from 'services/api-service/api.service';
import { CustomSelectOption } from '../../custom-select/models/custom-select-option';
import { Observable } from 'rxjs';
export class CustomAutocompleteService extends ApiService {

  constructor(http: HttpClient, prefix : string) {
      super(http,prefix);
  }


  public getDictionary(method : string,searchValue: any) : Observable<CustomSelectOption[]>{
    let httpParams : HttpParams = new HttpParams().set('search',searchValue);
    return this.getWithPrams<CustomSelectOption[]>(method,httpParams);
  }

}
