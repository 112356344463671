import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnSettings } from '../../column-settings';

@Component({
  selector: 'app-header-settings-modal',
  templateUrl: './header-settings-modal.component.html',
  styleUrls: ['./header-settings-modal.component.scss'],
})
export class HeaderSettingsModalComponent implements OnInit {
  tableSettingsForm: FormGroup;
  settings: ColumnSettings;

  @Output() onSave: EventEmitter<ColumnSettings> =
    new EventEmitter<ColumnSettings>();

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: InputHeaderSettingsModal
  ) {}

  get CheckColumnSelected() {
    return this.data?.columnSettings?.columns
      ?.filter((column) => !column.allwaysHidden && !column.allwaysShow)
      .every((x) => x.hidden);
  }

  ngOnInit(): void {
    this.tableSettingsForm = this.formBuilder.group({
      pageSize: new FormControl(this.data.columnSettings.pageSize.toString()),
      columns: this.formBuilder.group({}),
    });

    let cFG = this.getColumnsFormGroup();
    this.data.columnSettings.columns.forEach((col) => {
      if (!col.allwaysHidden && !col.allwaysShow)
        cFG.addControl(col.fieldName, new FormControl(!col.hidden));
    });
  }

  save() {
    let cols = this.tableSettingsForm.get('columns')?.value;
    let that = this.data.columnSettings;

    Object.keys(cols).forEach(function (key) {
      that.columns
        .filter((x) => x.fieldName === key)
        .map((x) => {
          x.hidden = !cols[key];
        });
    });

    this.settings = {
      pageSize: this.tableSettingsForm.value.pageSize,
      columns: that.columns,
    };

    this.onSave.emit(this.settings);
  }

  onChange(ob: MatCheckboxChange) {
    let cols = this.tableSettingsForm.get('columns')?.value;
    if (!Object.values(cols).some((value) => value === true)) {
      let cfg = this.getColumnsFormGroup();
      cfg.get(String(ob.source.name))?.patchValue(true);
    }
  }

  private getColumnsFormGroup() {
    return this.tableSettingsForm.get('columns') as FormGroup;
  }
}
export interface InputHeaderSettingsModal {
  title: string;
  columnSettings: ColumnSettings;
}
