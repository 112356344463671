import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedList } from '@app/core/models/paged-list.model';
import { BaseWebService } from 'app/core/services/base-web.service';
import { OrderDetails } from '../models/order-details.model';
import { InboundOrdersFilter } from '../models/order-paged-list-params.model';
import { OrderReport } from '../models/order-report.model';
import { Order } from '../models/order.model';

@Injectable()
export class InboundOrdersService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorsource/inboundorders', http);
  }

  getInboundOrdersPagedList(orderListParams: InboundOrdersFilter) {
    return this.postMethod<PagedList<Order>>('', orderListParams);
  }

  getInboundOrderDetails(id: number) {
    return this.getMethod<OrderDetails>(id.toString());
  }

  getExcelFile(orderListParams: InboundOrdersFilter) {
    return this.postMethodFile('GenerateExcel', orderListParams);
  }

  getPdfFile(orderListParams: InboundOrdersFilter) {
    return this.postMethodFile('GeneratePdf', orderListParams);
  }

  getReportsList(orderId: number) {
    return this.getMethod<OrderReport[]>(`Reports/${orderId}`);
  }
}
