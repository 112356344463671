import { Component, EventEmitter, Output } from '@angular/core';
import { FileDocument } from '../models/file-document.model';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-upload-panel',
  templateUrl: './upload-panel.component.html',
  styleUrl: './upload-panel.component.scss',
})
export class UploadPanelComponent {
  protected files: FileDocument[] = [];

  @Output() uploadedFilesEvent = new EventEmitter<FileDocument[]>();

  protected upload(e: FileList) {
    this.files = [];
    const fileListAsArray: any[] = Array.from(e);

    fileListAsArray.forEach((item, i) => {
      this.files.push(item);
    });
    let fileArr = uniqBy(this.files, (e) => {
      return e.name;
    }).map((item) => item);

    this.files = fileArr;

    this.uploadedFilesEvent.emit(this.files);
  }
}
