import {
  LinkObject,
  Media,
  News,
} from '../components/news-component/models/news.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private http: HttpClient) {}
  private readonly apiNews = 'wp-json/wp/v2/posts?context=embed&per_page=';

  getNews(maxNews: number): Observable<News[]> {
    return this.http.get<any[]>(this.getUrl(maxNews)).pipe(
      map<any[], News[]>((data) => {
        data.forEach((element) => {
          var link: LinkObject[] = element._links['wp:attachment'];
          element._links.attachment = link;
        });
        return data;
      })
    );
  }

  getNewsAttachment(linkAttachment: LinkObject): Observable<Media[]> {
    return this.http.get<Media[]>(linkAttachment.href);
  }

  private getUrl(maxNews: number): string {
    return environment.wordPressUrl + this.apiNews + maxNews;
  }
}
