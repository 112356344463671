<div class="help-container dialog-container">
  <div class="help-container__top">
    <div class="help-container__top-header">
      <p>Help Topics</p>
      <div class="help-container__top-belt"></div>
    </div>

    <div class="help-container__top-exit">
      <mat-icon (click)="closeModal()">close</mat-icon>
    </div>
  </div>
  <div class="help-container__content">
    <div class="help-container__content-left">
      <div class="help-container__content-1"></div>
      <div class="help-container__content-2">
        <tr
          class="help-container__content-left-row"
          *ngFor="let item of data; let i = index"
          (click)="onClickLeftRow(i)"
        >
          <td
            style="padding-right: 10px"
            [ngClass]="{
              'help-container__content-left-row --active': item.active
            }"
          >
            <span
              [style.visibility]="item.active ? 'visible' : 'hidden'"
              class="help-container__left-belt"
            ></span
            >{{ item.name }} <span style="font-family: monospace">></span>
          </td>
        </tr>
      </div>
    </div>
    <div class="help-container__content-mid">
      <div class="help-container__content-1"></div>
      <div class="help-container__content-2">
        <tr
          class="help-container__content-left-row"
          *ngFor="let item of midData; let i = index"
          (click)="onClickMidRow(i)"
        >
          <td
            style="padding-right: 10px"
            [ngClass]="{
              'help-container__content-left-row --active': item.active
            }"
          >
            <span
              [style.visibility]="item.active ? 'visible' : 'hidden'"
              class="help-container__left-belt"
            ></span
            >{{ item.name }} <span style="font-family: monospace">></span>
          </td>
        </tr>
      </div>
    </div>
    <div class="help-container__content-right">
      <ng-template #viewContainerRef></ng-template>
    </div>
  </div>
  <div class="help-container__bottom"></div>
</div>
