import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import * as uuid from 'uuid';
import { CustomSelectOption } from './models/custom-select-option';
@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  @Input('placeholder') placeholder : string = "";
  @Input('formControl') formControl : FormControl;
  @Input('customClass') customClass : string;
  @Input('label') label : string;
  @Input('id') id : string = uuid.v4();
  @Input('disabled') disabled : boolean;
  @Input('withNullOption') withNullOption : boolean;
  @Input('options') options : CustomSelectOption[];
  @Output() onValueChanges = new EventEmitter<any>();
  @ViewChild('selectInput', { static: true }) selectInput: ElementRef;
  protected hasErrors : boolean = false;
  @Input() required : boolean = false;
  constructor() { }

  ngOnInit() {
    if (this.formControl.hasValidator(Validators.required)) {
      this.required = true;
     }
    this.initOptions();
    fromEvent(this.selectInput.nativeElement, 'change').subscribe((value)=>{
      this.hasErrors = false;
      this.onValueChanges.emit((value as any).srcElement.value);
      if((value as any).srcElement.value === "null") {
        this.formControl.setValue(null);
      }
      if(!!this.formControl.errors){
        this.hasErrors = true;
     }
    })
  }

  private initOptions(){
    if(this.withNullOption){
      this.options.unshift({ text:'', value:'' });
    }
  }

}
