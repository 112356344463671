import { Component, Input, OnInit } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { DateAdapter,MatDateFormats } from '@angular/material/core';
import { Moment } from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { CustomDateServiceService } from 'services/custom-date-service/custom-date-service.service';
export interface DateParams {
  dateFormat: string | null;
  onlyDate : boolean;
}

@Component({
  template: `{{ 
    localeDate
  }}`,
  providers:[MomentDateAdapter]
})
export class DateColumnComponent implements ColumnComponent {
  @Input() params: ColumnElementParams;

  constructor(private _customDateServiceService : CustomDateServiceService){}

  get localeDate(){
    return this._customDateServiceService.setDate(this.params.data[this.params.fieldName]).toLocaleStringWithTimezone(this.params.params.dateFormat,this.params.params.onlyDate);
  }
}
