<div class="custom-form-group">
  <label [for]="id" *ngIf="!!label" class="custom-label-input">{{label}}</label>
  <div class="custom-text-input-wrapper">
      <input
      class="custom-check-box"
      [class.--error]="hasErrors"
      [formControl]="formControl"
      type="checkBox"
      [id]="id"
      [attr.disabled]="disabled ? true : null"
      />
  </div>
</div>
