import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnSettings } from '../../column-settings';
import { Column } from '../column-container/column';
import { HeaderSettingsModalComponent } from './header-settings-modal.component';

@Component({
  selector: 'column-header-settings',
  templateUrl: './column-header-settings.component.html',
  styleUrls: ['./column-header-settings.component.scss'],
})
export class ColumnHeaderSettingsComponent implements OnInit {
  @Input() columns: Column[] = [];
  @Input() pageSize: number = 10;
  @Input() title: string;
  @Output() onColumnSettingsChange: EventEmitter<ColumnSettings> =
    new EventEmitter<ColumnSettings>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(HeaderSettingsModalComponent, {
      data: {
        title: this.title,
        columnSettings: { columns: this.columns, pageSize: this.pageSize },
      },
    });

    const onSaveSub = dialogRef.componentInstance.onSave.subscribe((result) => {
      if (this.onColumnSettingsChange) this.onColumnSettingsChange.emit(result);
    });

    dialogRef.afterClosed().subscribe(() => {
      onSaveSub.unsubscribe();
    });
  }
}
