import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'services/api-service/api.service';
import { HelpContactForm } from '../help/components/help-contact-form/models/help-contact-form';

@Injectable({
  providedIn: 'root'
})
export class HelpContactService extends ApiService {

  constructor(http: HttpClient) {
    super(http,"/api/identity/contact");
   }
  sendMail(data : HelpContactForm){
    return this.post('contact',data);
  }

}
