<div class="order-container">
  <div *ngIf="!disabledMode"
    appDragDropFileUpload
    class="drag-and-drop border-primary-A400 bg-primary-50"
    (fileDropped)="upload($event)"
    name="documents"
  >
    <mat-icon class="cloud-upload-icon">cloud_upload</mat-icon>
    <span class="info"
      >Drag & drop files or
      <a id="upload_link" class="upload-link" (click)="uploadField.click()"
        >Browse</a
      >​</span
    >
    <span class="sub-info"></span>
    <input
      type="file"
      name="upload-input"
      (change)="upload($any($event).target.files)"
      #uploadField
      hidden
      multiple
    />
  </div>
  <div class="uploaded" *ngIf="documents && documents.length > 0">
    <span>Uploaded</span>
    <div class="document-list">
      <div
        class="document text-primary"
        *ngFor="let file of documents; let i = index"
      >
        <span *ngIf="disabledMode" (click)="downloadDoc(file.id)">{{
          file.name
        }}</span>
        <span *ngIf="!disabledMode">{{ file.name }}</span>
        <div class="fileOptions">
          <app-custom-input *ngIf="addDescription" class="form-field" [formControl]="getFormControl(file.name)" ngDefaultControl [label]="'Description'"></app-custom-input>
          <button
            *ngIf="!disabledMode"
            mat-mini-fab
            color="primary"
            (click)="deleteDocument(file.name)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
