import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warn-dialog',
  templateUrl: './warn-dialog.component.html',
  styleUrls: ['./warn-dialog.component.scss'],
})
export class WarnDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public warnSettings: warnSettings) {}

  ngOnInit() {}
}

export interface warnSettings {
  title: string;
  message: string;
  onlyClose?: boolean;
}
