<div *ngIf="turnedOn" class="overlay">
  <div class="spinner">
    <div class="spinner__circle">
      <div class="spinner__circle-gradient"></div>

      <div class="spinner__circle-inner"></div>

    </div>
    <div class="logo">
      <img class="logo__src" />
    </div>
  </div>

</div>

