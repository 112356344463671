<div class="selector-container">
  <div class="division">
    <button mat-button [matMenuTriggerFor]="divisionOptions">
      <span class="button-text">{{ selectedDivision.name }}</span>
      <mat-icon class="text-primary-A200">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #divisionOptions="matMenu">
      <button
        mat-menu-item
        *ngFor="let div of divisions"
        (click)="selectDivision(div.id)"
      >
        {{ div.name }}
      </button>
    </mat-menu>
  </div>
</div>
