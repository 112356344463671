<div class="custom-form-group">
    <label [for]="id" *ngIf="!!label" class="custom-label-input">{{label}}<span class="custom-input-red" *ngIf="required">*</span></label>
    <div class="custom-text-input-wrapper">
        <input *ngIf="matAutocomplete"
        class="custom-text-input"
        [class.--on-submit-error]="formControl.invalid"
        [class.--error]="hasErrors"
        [class.custom-input-disabled]="formControl.disabled"
        [ngClass]="customClass"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [type]="showPasswordInput ? 'text' : type ? type : 'text'"
        [id]="id"
        [attr.disabled]="disabled ? true : null"
        [matAutocomplete]="matAutocomplete"
        />
        <input *ngIf="!matAutocomplete"
        class="custom-text-input"
        [class.--on-submit-error]="formControl.invalid"
        [class.--error]="hasErrors"
        [class.custom-input-disabled]="formControl.disabled"
        [ngClass]="customClass"
        [formControl]="formControl"
        [placeholder]="placeholder"
        [type]="showPasswordInput ? 'text' : type ? type : 'text'"
        [id]="id"
        [attr.disabled]="disabled ? true : null"
        />
        <mat-icon (click)="handlePasswordInput()" *ngIf="type === 'password'" class="custom-input-visibility-icon">visibility</mat-icon>
    </div>
</div>
