<div *ngIf="loaded" class="selector-container">
  <div  *ngIf="accounts.length > 1" class="account">
    <button mat-button [matMenuTriggerFor]="accountOptions">
      <span class="button-text">{{ selectedAccount.name }}</span>
      <mat-icon class="text-primary-A200">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #accountOptions="matMenu">
      <button
        mat-menu-item
        *ngFor="let div of accounts"
        (click)="selectAccount(div.id)"
      >
        {{ div.name }}
      </button>
    </mat-menu>
  </div>
  <div class="division" [class.division-display-none]="!((divisions.length > 1 || selectedDivision.id > 0) && !onlyAccounts)">
    <button mat-button [matMenuTriggerFor]="divisionOptions">
      <span class="button-text">{{ selectedDivision.name }}</span>
      <mat-icon class="text-primary-A200">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #divisionOptions="matMenu">
      <button
        mat-menu-item
        *ngFor="let div of divisions"
        (click)="selectDivision(div.id)"
      >
        {{ div.name }}
      </button>
    </mat-menu>
  </div>

  <div class="program" *ngIf="(programs.length > 1 || selectedProgram.id > 0)  && !onlyAccounts">
    <button mat-button [matMenuTriggerFor]="programOptions">
      <span class="button-text">{{ selectedProgram.name }}</span>
      <mat-icon class="text-primary-A200">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #programOptions="matMenu">
      <button
        mat-menu-item
        *ngFor="let pro of programs"
        (click)="selectProgram(pro.id)"
      >
        {{ pro.name }}
      </button>
    </mat-menu>
  </div>
  <div class="subPprogram" *ngIf="(subProgram.length > 1 || selectedSubProgram.id > 0) && !hideSubprogram  && !onlyAccounts">
    <button mat-button [matMenuTriggerFor]="subProgramOptions">
      <span class="button-text">{{ selectedSubProgram.name }}</span>
      <mat-icon class="text-primary-A200">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #subProgramOptions="matMenu">
      <button
        mat-menu-item
        *ngFor="let spro of subProgram"
        (click)="selectSubProgram(spro.id)"
      >
        {{ spro.name }}
      </button>
    </mat-menu>
  </div>
</div>
