import { Component, Input, OnInit } from '@angular/core';
import { News } from './models/news.model';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-news-component',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  providers: [NewsService],
})
export class NewsComponent implements OnInit {
  @Input() maxNews: number = 3;
  @Input() withHeaderText: boolean = true;

  protected newsList: News[] = [];
  protected header: string = "Latest in IT Asset Managment";

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    this.newsService.getNews(this.maxNews).subscribe((result) => {
      this.newsList = result;
    });
  }
}
