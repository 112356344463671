import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  duration: number = 3000;

  constructor(private _snackBar: MatSnackBar) {}

  success(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: ['success-alert'],
    });
  }
  close() {
    this._snackBar.dismiss();
  }
  error(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: ['error-alert'],
    });
  }

  errorHttpErrorResponse(response: HttpErrorResponse) {
    let config = {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: ['error-alert'],
    };

    if (response.error.errors) {
      Object.keys(response.error.errors).forEach((e) => {
        this._snackBar.open(response.error.errors[e], '', config);
      });
    } else {
      this._snackBar.open(response.error, '', config);
    }
  }
}
