import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'services/api-service/api.service';
import { TokenModel } from '../models/token.model';

@Injectable()
export class TokenService extends ApiService {
  constructor(http: HttpClient) {
    super(http, '/api/identity');
  }

  resetToken() {
    return this.post<TokenModel>('Auth/ResetToken', null);
  }
}
