<div #scrollBelt class="custom-table__overflow-belt"></div>
<mat-spinner *ngIf="loading"></mat-spinner>
<table
  mat-table
  [dataSource]="data"
  class="custom-table"
  [ngClass]="loading ? '--loading' : ''"
  (contentChanged)="afterContentChange($event)"
>
  <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef>
      <div class="custom-table-header">
        <column-header-check-box-component
          *ngIf="isCheckBoxColumn(column)"
          (changed)="onChangeCheckBoxHeader($event)"
        >
        </column-header-check-box-component>
        <column-header-component
          *ngIf="!isCheckBoxColumn(column)"
          [header]="getColumnHeader(column)"
          (sortColumn)="sortChange($event)"
        ></column-header-component>
        <column-header-settings
          *ngIf="hasCustomButton(column) && !isCheckBoxColumn(column)"
          [columns]="columnDefinitions"
          [pageSize]="settings.pageSize"
          [title]="titleCustomizeTable"
          (onColumnSettingsChange)="onSettingsChange($event)"
          class="custom-column-icon"
        >
        </column-header-settings>
      </div>
    </th>
    <td
      mat-cell
      *matCellDef="let element"
      [ngClass]="isCellToMark(column, element)"
    >
      <column-check-box-component
        *ngIf="column == 'CheckBox'"
        [params]="element"
        [check]="headerCheck"
        (changed)="onChangeCheckBoxColumn($event)"
      ></column-check-box-component>
      <column-container-component
        *ngIf="column != 'CheckBox'"
        [column]="getColumnDefinition(column)"
        [data]="element"
      ></column-container-component>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnsToDisplay"
    class="custom-table-data"
  ></tr>
</table>

<div
  *ngIf="!hiddePaginator"
  class="custom-table-paginator"
  [ngClass]="loading ? '--loading' : ''"
>
  <mat-paginator
    [length]="this.settings.totalCount"
    [pageSize]="this.settings.pageSize"
    [pageIndex]="this.settings.pageNumber"
    [pageSizeOptions]="[10, 20, 50]"
    (page)="onChange($event)"
    hidePageSize="true"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
