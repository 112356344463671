<div class="custom-form-group">
  <label [for]="id" *ngIf="!!label" class="custom-multi-select-label">{{label}}<span class="custom-multi-select-red" *ngIf="required">*</span></label>
  <div [ngClass]="{'custom-multi-select-input-no-label': !label}" class="custom-multi-select-input-wrapper">
    <ng-multiselect-dropdown
        [placeholder]="placeholder"
        [settings]="dropdownSettings"
        [class.--on-submit-error]="formControl.invalid"
        [data]="options.length ? options : []"
        [formControl]="formControl"
        (onSelect)="itemSelect($event)"
        (onSelectAll)="selectAll($event)"
        [disabled]="disabled"
        (ngModelChange)="change($event)"
        class="custom-multi-select-input"
        [id]="id"
        (click)="onClick()"
        (onFilterChange)="onFilterChange()"
      >
    </ng-multiselect-dropdown>
  </div>
</div>
