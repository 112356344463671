<div class="dialog-container">
  <p>{{warnSettings.title}}</p>
  {{warnSettings.message}}
  <div class="button-section">
    <app-button *ngIf="!warnSettings.onlyClose"
      color="accent-empty"
      text="Cancel"
      [mat-dialog-close]="false"
    ></app-button>
    <app-button text="Yes" *ngIf="!warnSettings.onlyClose" [mat-dialog-close]="true"></app-button>
    <app-button *ngIf="warnSettings.onlyClose"
      text="Close"
      [mat-dialog-close]
    ></app-button>
  </div>
</div>
