import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'app/core/models/dictionary.model';
import { BaseWebService } from 'app/core/services/base-web.service';

@Injectable()
export class DivisionsApiService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorservice/Divisions', http);
  }

  getByLoggedUser() {
    return this.getMethod<Dictionary[]>(``);
  }

  getByAccount(accountId: number) {
    return this.getMethod<Dictionary[]>(`${accountId}`);
  }
}
