<div class="dialog-container">
  <p>{{ data.title }}</p>
  <form [formGroup]="tableSettingsForm">
    <div class="page-size-selector">
      <span>Items per page</span>
      <div class="text-primary border-primary page-size-selector-select">
        <mat-form-field>
          <mat-select formControlName="pageSize">
            <mat-option value="5">5</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="50">50</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="column-selector" formGroupName="columns">
      <div
        class="column-checkbox"
        *ngFor="let column of data.columnSettings.columns"
        [ngStyle]="{ display: column.allwaysHidden ? 'none' : 'block' }"
      >
        <mat-checkbox
          *ngIf="!column.allwaysHidden && !column.allwaysShow"
          [formControlName]="column.fieldName"
          [checked]="!column.hidden"
          (change)="onChange($event)"
          [name]="column.fieldName"
          color="primary"
          >{{ column.header }}</mat-checkbox
        >
      </div>
    </div>
    <span class="column-info" *ngIf="CheckColumnSelected"
      >At least one column must be selected.</span
    >
  </form>
  <div class="button-section">
    <app-button
      color="accent-empty"
      text="Cancel"
      mat-dialog-close
    ></app-button>
    <app-button
      text="Save"
      color="light-primary"
      (btnClick)="save()"
      mat-dialog-close
    ></app-button>
  </div>
</div>
