<div class="custom-form-group">
  <label
    [for]="id"
    *ngIf="!!label"
    class="custom-label-input"
    ngDefaultControl
    >{{ label }}</label
  >
  <div class="custom-autocomplete-wrapper">
    <input
      class="custom-autocomplete"
      [class.--on-submit-error]="formControl.invalid"
      [class.--error]="hasErrors"
      [ngClass]="customClass"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [id]="id"
      #searchInput
      matInput
      type="text"
      [matAutocomplete]="auto"
    />
    <div class="icon-container" *ngIf="isSearching">
      <i class="loader"></i>
    </div>
    <mat-autocomplete
      [displayWith]="displayFn.bind(this)"
      #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions"
        [value]="option"
        [matTooltip]="option.text"
      >
        {{ option.text }}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
