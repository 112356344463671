import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ControlType, QuestionBase } from '@app/shared/dynamicForm/QuestionBase';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  protected formControl:FormControl;
  protected controlType = ControlType;

  ngOnInit(): void {
    this.formControl = this.form.controls[this.question.key] as FormControl;
  }

  @Input() question!: QuestionBase<any>;
  @Input() form!: FormGroup;
  get isValid() { return this.form.controls[this.question.key].valid; }

}
