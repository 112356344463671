import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from 'app/core/models/dictionary.model';
import { BaseWebService } from 'app/core/services/base-web.service';
import { CustomSelectOption } from '../components/input/custom-select/models/custom-select-option';

@Injectable()
export class ProgramsApiService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('makorservice/Programs', http);
  }

  getAllProgramsDictionary(divisionId: number) {
    return this.getMethod<Dictionary[]>(`${divisionId}`);
  }

  getProgramsDictionary(divisionId: number) {
    return this.getMethod<Dictionary[]>(`?divisionsIds=${divisionId}`);
  }

  getMultipleProgramsDictionary(url: string) {
    return this.getMethod<Dictionary[]>(`${url}`);
  }

  getSubprogramOptions(programId: number) {
    return this.getMethod<CustomSelectOption[]>(
      `SubprogramOptions/${programId}`
    );
  }

  getMultiSubprogramOptions(url: string) {
    return this.getMethod<CustomSelectOption[]>(`SubprogramOptions/${url}`);
  }
}
