import { Component, Input, ElementRef } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

export interface IconColumnSetting {
  onClick?: (data: any) => void;
  icon: string;
  visible?: (data: any) => boolean;
}

@Component({
  template: `<button *ngIf="show" mat-flat-button (click)="onClick()">
    <mat-icon>{{ this.params.params.icon }}</mat-icon>
  </button>`,
})
export class IconColumnComponent implements ColumnComponent {
  @Input() params: ColumnElementParams;
  show: boolean = true;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.params.params.visible)
      this.show = this.params.params?.visible(this.params);
  }

  onClick(): void {
    if (this.params.params.onClick) this.params.params?.onClick(this.params);
  }
}
