import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

@Component({
  selector: 'column-check-box-component',
  template: `<div style="margin: 5px;"><app-custom-check-box [formControl]="formContorl" ngDefaultControl></app-custom-check-box></div>`
})
export class CheckBoxColumnComponent implements OnChanges {

  @Input() params: ColumnElementParams;
  @Input() check: boolean = false;

  @Output() changed = new EventEmitter<any>();

  protected formContorl = new FormControl(false);

  ngOnInit(): void {
    this.formContorl.setValue(this.check);
    this.formContorl.valueChanges.subscribe((data)=>{
      if(data!=null && typeof(data) === "boolean")
      {
        this.changed.emit({ value: data, params:this.params });
        this.check = data;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['check'].firstChange) {
      this.formContorl.setValue(changes['check'].currentValue);
    }
  }
}
