<mat-card class="card">
  <mat-card-content class="card-content">
    <div class="gridContainer">
      <img class="gridImg" *ngIf="showImg"  mat-card-md-image src="{{mediaUrl}}">
      <div class="gridSubGrid">
        <a class="subGridLink" target="_blank" rel="noopener noreferrer" href="{{news.link}}" mat-card-title style="font-size:1.2rem">
          {{ news.title.rendered }}
          <mat-icon class="bottom-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="opne-in-tab"></mat-icon>
        </a>
        <div class="subGridExcert" [innerHTML]="news.excerpt.rendered"></div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

