<div
  appDragDropFileUpload
  class="drag-and-drop border-primary-A400 bg-primary-50"
  (fileDropped)="upload($event)"
  name="documents"
>
  <mat-icon class="cloud-upload-icon">cloud_upload</mat-icon>
  <span class="info"
    >Drag & drop files or
    <a id="upload_link" class="upload-link" (click)="uploadField.click()"
      >Browse</a
    >​</span
  >
  <input
    type="file"
    name="upload-input"
    (change)="upload($any($event).target.files)"
    #uploadField
    hidden
    multiple
  />
</div>
